<template>
  <div ref="refetch" :class="!isDisabled ? 'refetch not-disable':'refetch'">
    <artmisads-select-button size="small" class="refetch-button" @click="getData" :disabled="isDisabled">
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#icon-gengxin"></use>
      </svg>
      <span v-if="isDisabled">Updating...</span>
      <span v-else>Update</span>
      <a-tooltip placement="bottom" :getPopupContainer="() => $refs.refetch" >
        <template #title>
          <div class="pop-title">Update brand and product information from Amazon. This button can only be clicked once every 10 minutes.</div>
        </template>
        <svg class="icon wenhao" aria-hidden="true">
          <use xlink:href="#icon-wenhao-tianchong"></use>
        </svg>
      </a-tooltip>

    </artmisads-select-button>
    <div v-show="!isDisabled" class="time">
      <span>Latest Update at: </span>
      <span v-show="updateTime">{{ moment(updateTime).format("YYYY-MM-DD HH:mm") }}</span>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, onUnmounted, computed } from 'vue';
import moment from 'moment';
import { message } from 'ant-design-vue';
import { brandApi, productsApi } from '@/server/index';
import { useSystemStore } from '@/storeData/system';
import { useUserInfoStore } from '@/storeData';
const props = defineProps(['type','refetch']);
const userStore=useUserInfoStore();
const systemStore = useSystemStore();
const refetch = ref();
const updateTime = ref();
let firstTime=null;
let intervalId;
const remainingTime = ref(0);
const disableDuration = 600; // 10 minutes = 600 seconds
const ACTION=props.type==='brand'?brandApi:productsApi;
const currLocalKey=props.type==='brand'?'brandUpdateBtn':'productUpdateBtn';
const countryCode=userStore.userInfo.countryCode;
onMounted(()=>{
  init()
})
const init= async()=>{
  await getTime();
  let btn=JSON.parse(localStorage.getItem(currLocalKey));
  let status=false;
  btn && btn.forEach(v=>{
    if(countryCode==v?.site){
      let now=moment().valueOf();
      let expire=moment(now).diff(moment(v.time),'seconds');
      // let hasUpdated=moment(updateTime).diff(moment(v.oldTime),'seconds');
      // if(hasUpdated){//
      //   changeBtnStatus(false);
      //   return
      // }
      if(expire<disableDuration){
        status = v.status;
        remainingTime.value =disableDuration - expire;
        intervalId = setInterval(() => {
          remainingTime.value -= 1; 
          // console.log(remainingTime.value);
          if (remainingTime.value <= 0) {
            clearInterval(intervalId);
            changeBtnStatus(false);
            remainingTime.value = 0;
            getTime();
          }
        },1000);
      }
      
    }
  })
  systemStore.setBtnStatus(props.type,status);

}
const isDisabled = computed(()=>{
  if(props.type==='brand'){
    return systemStore.brandUpdateBtn;
  }
  if(props.type==='product'){
    return systemStore.productUpdateBtn;
  }
  
})
const getTime = async () => {
  let res=await ACTION.latestUpdateTime();
  if(res.success){
    updateTime.value = res.data;
    if(!firstTime){
      firstTime=res.data
    }else if(firstTime !== res.data){
      props.refetch()
    }
  }
}
const getData = () => {
  message.warn('Updating...Please refresh and check later.')
  handleClick();
  ACTION.refetchData();
}
// 处理按钮点击事件
function handleClick() {
  if (isDisabled.value) return;
  changeBtnStatus(true);
  remainingTime.value = disableDuration;
  intervalId = setInterval(() => {
    remainingTime.value -= 1; 
    // console.log(remainingTime.value);
     
    if (remainingTime.value <= 0) {
      clearInterval(intervalId);
      changeBtnStatus(false);
      remainingTime.value = 0;
      getTime();//再次请求时间，时间有变化表示更新成功，然后重新拉取列表接口
    }
  },1000);
}
const changeBtnStatus=status=>{
  let item={
    status:status,
    time:moment().valueOf(),
    site:countryCode,
    oldTime:firstTime
  }
  systemStore.setBtnStatus(props.type,status);
  let _item=JSON.parse(localStorage.getItem(currLocalKey)) || [];
  let hasCurr=_item.findIndex(v=>v.site===countryCode);  
  if(hasCurr == -1){
    _item.push(item);
  }else{
    _item.splice(hasCurr,1)
  }
  localStorage.setItem(currLocalKey,JSON.stringify(_item))
}

onUnmounted(() => {
  clearInterval(intervalId);
  updateTime.value=undefined
});

</script>
<style lang="less" scoped>
.refetch:deep {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  .refetch-button {
    display: flex;
    align-items: center;
    margin-right: 8px;
    color: var(--dark-2);
    font-size: 13px;
    letter-spacing: 0.3px;
    line-height: 18px;

    >span {
      margin-left: 6px;
      margin-right: 2px;
    }

    .icon {
      font-size: 16px;
      &:focus{
        border: unset;
        outline: unset;
      }
    }
    .wenhao{
      font-size: 14px;
      color: var(--dark-4);
    }
  }
  .ant-tooltip-inner {
    font-size: 14px;
    width: 300px;
  }
  .time{
    color: var(--dark-3);
    font-size: 13px;
    letter-spacing: 0.3px;
    line-height: 19px;
  }
}
.not-disable:deep{
  .ant-tooltip-arrow {
    left: 42%;
  }
}
</style>