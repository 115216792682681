<template>
  <div class="set-commission" ref="setCommissionRef">
    <artmisads-modal
      v-model:open="props.showSetCommission"
      :title="modalTitle"
      :width="600"
      okText="Confirm"
      :onCancel="onCancel"
      :getContainer="() => setCommissionRef"
      :destroyOnClose="true"
      :maskClosable="false"
    >
      <div class="set-commission-wrap">
        <p class="first-p">
          The public commission is displayed to all publishers/creators when the
          product is active on the platform. ArtemisAds Fees are displayed to
          you and ArtemisAds only.
        </p>
        <div class="content">
          <div class="commission">
            <div class="commission-input">
              <span>Publisher Commission</span>
              <artmisads-input-number
                :min="0"
                :max="100"
                suffix="%"
                :step="0.5"
                size="large"
                @blur="commissionBlur"
                v-model:value="commission"
                @change="handleChangeCommission($event)"
              />
              <span
                v-if="showDefalutText"
                class="defalut-text"
                @click="clickDefalutText"
                >Please enter...</span
              >
            </div>
            <span>+</span>
            <div class="fees">
              <span
                >ArtemisAds Fees<a-tooltip
                  :getPopupContainer="() => $refs.setCommissionRef"
                >
                  <template #title
                    >Publisher Commission x 20%, Max 3.5%</template
                  >
                  <Icon
                    name="wenhao-tianchong"
                    class="question-icon"
                  /> </a-tooltip
              ></span>
              <artmisads-button disabled
                >{{ fees.toFixed(1) }}%</artmisads-button
              >
            </div>
            <span>=</span>
            <div class="total">
              <span>Total</span>
              <artmisads-button disabled>
                <span>{{
                  commission
                    ? parseFloat(Number(fees) + Number(commission)).toFixed(1)
                    : Number(fees).toFixed(1)
                }}</span>
                <span>%</span>
              </artmisads-button>
            </div>
          </div>
          <p class="second-p">
            You could receive an average of 10% of the sales back from Amazon as
            Brand Referral Bonus.
          </p>
          <p class="third-p">
            The percentages displayed above will be applied to sales data that
            ArtemisAds receives from Amazon Attribution. Please note that sales
            reported by Amazon Attribution excludes Discounts (Instant
            Discount), Prime Day Deals, Prime Exclusive Deals, and Limited Time
            Deals, while DOES NOT exclude discounts from Promo Codes, Chippable
            Coupons, Subscribe & Save, or Lightning Deals.
          </p>
        </div>
      </div>
      <template #footer>
        <artmisads-button @click="saveCommission" type="primary"
          >Save Commission</artmisads-button
        >
      </template>
    </artmisads-modal>
  </div>
</template>

<script setup>
import { message } from 'ant-design-vue'
import Icon from '@/components/Icon.vue'
import { ref, onBeforeMount, computed, onMounted, watch } from 'vue'
import { productsApi, brandApi } from '../../../server/index'
const showDefalutText = ref(true)
const commissionMinRef = ref(null)
const props = defineProps([
  'type',
  'setSelectSingleProduct',
  'selectSingleProduct',
  'showSetCommission',
  'selectedRowKeys',
  'productList',
  'setShowSetCommission',
  'getProductList',
])
const defaultCommission = ref({
  defaultCommissionRate: 1,
  platformFeeFactor: 1,
  platformMaxFeeRate: 1,
})
const commission = ref('')

const onCancel = () => {
  props.setShowSetCommission(false)
  props.setSelectSingleProduct('')
  commission.value = ''
}
const modalTitle = computed(() => {
  if (props.type == 'product') {
    return `Set Public Commission for ${
      props.selectedRowKeys.length || 1
    } Product${props.selectedRowKeys.length > 1 ? 's' : ''}`
  }
  if (props.type == 'brand') {
    return `Set Public Commission for all Products under this Brand`
  }
  if (props.type == 'product-detail') {
    return `Set Public Commission`
  }
})

const handleChangeCommission = (e) => {
  commission.value = e;
}
const fees = computed(() => {
  let value = 0
  if (commission.value) {
    value = (commission.value * defaultCommission.value.platformFeeFactor) / 100
  } else {
    value =
      (defaultCommission.value.defaultCommissionRate *
        defaultCommission.value.platformFeeFactor) /
      100
    if (commission.value == 0) {
      value =
        (commission.value * defaultCommission.value.platformFeeFactor) / 100
    }
  }
  if (value >= defaultCommission.value.platformMaxFeeRate) {
    value = defaultCommission.value.platformMaxFeeRate
  }
  let arr = value && value.toString().split('.')
  let firstNumber = arr && Number(arr[0])
  let middleNum = '0'
  if (arr && arr.length > 1) {
    middleNum = Number(arr[1][0])
    if (arr[1][1] == 5) {
      if (arr[1][0] % 2 !== 0) {
        middleNum += 1
        if (arr[1][0] == 9) {
          middleNum = 0
          firstNumber += 1
        }
      }
    } else if (arr[1][1] > 5) {
      middleNum += 1
      if (arr[1][0] == 9) {
        middleNum = 0
        firstNumber += 1
      }
    }
  }
  let endNum = arr && parseFloat(firstNumber + '.' + middleNum)
  return endNum
})

const setCommissionRef = ref(null)

watch(
  () => commission.value,
  (newValue) => {
    showDefalutText.value = newValue === null
  }
)

function commissionBlur(e) {
  const newValue = e.target.value
    .replace(/[^0-9.]/g, '')
    .split('.')
    .map((v, i) => {
      v = v ? parseInt(v) : null
      console.log(`value: ${v}, index: ${i}`)

      if (i && v) {
        v = 5
      }

      return v
    })
    .join('.')
  let data = Number(newValue);
  data = data > 99 ? 99 : data;
  // 将替换后的字符串转换为数字并赋值给 commission
  commission.value = data;
}

const saveCommission = async () => {
  if (props.type == 'product' || props.type == 'product-detail') {
    const params = {
      commissionRate: commission.value,
      ids: props.selectSingleProduct
        ? [props.selectSingleProduct]
        : props.selectedRowKeys,
      platformFeeRate: fees.value,
    }
    const res = await productsApi.setCommission(params)
    const { success, error } = res
    if (success) {
      onCancel()
      message.success('Success')
      if (props.type == 'product') {
        props.getProductList()
      } else {
        props.getProductList(params.ids)
      }
    }
  }
  if (props.type == 'brand') {
    let req = {
      id: props.selectSingleProduct,
      commissionRate: commission.value,
    }
    brandApi.setBrandPer(req).then((res) => {
      if (res.success) {
        onCancel()
        props.getProductList()
        message.success('Success!', 1)
      }
    })
  }
}

const clickDefalutText = () => {
  showDefalutText.value = false
  commissionMinRef.value.focus()
}

const getRate = async () => {
  const res = await productsApi.getCommissionConfig()
  const { success, data, error } = res
  if (success) {
    defaultCommission.value = data
  }
}

const setDefaultCommission = () => {
  const selectedRowKeysLength = props.selectedRowKeys.length

  if (selectedRowKeysLength === 1) {
    commission.value = props.productList.find(
      (item) => item.id === props.selectedRowKeys[0]
    ).commission
  } else if (selectedRowKeysLength > 1) {
    commission.value = defaultCommission.value.defaultCommissionRate
  }
  if (props.selectSingleProduct) {
    if (props.type == 'product-detail') {
      commission.value = props.productList.commission
    } else {
      commission.value = props.productList.find(
        (item) => item.id === props.selectSingleProduct
      ).commission
    }
  }
}

watch(props, () => {
  setDefaultCommission()
})

onBeforeMount(() => {
  getRate()
})
</script>

<style lang="less" scoped>
.set-commission {
  :deep(.ant-modal) {
    .ant-modal-body {
      padding: 0;
      border: none;
    }
  }
  :deep(.ant-tooltip) {
    &-inner {
      width: 260px;
      font-size: 12px;
      white-space: nowrap;
    }
  }
  .set-commission-wrap {
    p {
      font-family: Sora, sans-serif;
    }
    .first-p {
      padding: 24px 17px 24px 24px;
      color: var(--dark-3);
      font-weight: 400;
      line-height: 22px;
    }
    .third-p {
      margin-top: 28px;
      background: var(--big-bg-2);
      border-radius: 8px;
      padding: 8px 12px;
      font-size: var(--font-size-min);
      color: var(--dark-3);
      letter-spacing: 0.2px;
      line-height: 18px;
    }
    .second-p {
      font-size: var(--font-size-min);
      align-self: end;
      padding: 8px 12px;
      margin-top: 15px;
      width: 286px;
      background: var(--color-white);
      border: 1px solid var(--border-4);
      box-shadow: var(--section-box-shadow-2);
      border-radius: 8px;
      color: var(--dark-3);
      letter-spacing: 0.2px;
      line-height: 16px;
      position: relative;
      font-style: italic;
      &::before {
        content: '';
        position: absolute;
        top: -5px;
        right: 130px;
        width: 8px;
        height: 8px;
        border: 1px solid var(--border-1);
        border-color: var(--border-4) transparent transparent var(--border-4);
        background-color: #ffffff;
        transform: rotate(45deg);
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    padding: 0 24px 24px 24px;
    .commission {
      display: flex;
      > span {
        align-self: center;
        margin: 20px 10px 0 10px;
        font-family: Sora, sans-serif;
        font-weight: 500;
        color: #0f0629;
      }
      .fees,
      .total,
      .commission-input {
        display: flex;
        flex-direction: column;
        > span {
          margin-bottom: 6px;
          font-weight: 500;
          color: var(--dark-2);
          line-height: 19px;
        }
      }
      .commission-input {
        position: relative;
        .defalut-text {
          position: absolute;
          font-family: Sora, sans-serif;
          color: var(--dark-4);
          line-height: 20px;
          left: 15px;
          top: 34px;
          margin: 0;
        }
        .haifenhao {
          position: absolute;
          font-weight: 500;
          font-size: var(--font-size-large);
          color: var(--dark-1);
          right: 30px;
          top: 34px;
          margin: 0;
        }
        :deep(.ant-input-number) {
          width: 200px;
          .ant-input-number-handler-wrap {
            opacity: 1;
          }
        }
      }
      .total {
        > button {
          width: 96px;
          display: flex;
          justify-content: space-between;
          > span {
            align-self: center;
          }
        }
      }
      .fees {
        > button {
          width: 144px;
        }
        .question-icon {
          margin-left: 3px;
          color: var(--yellow-4);
        }
      }
      .fees,
      .total {
        > button {
          padding-left: 12px;
          text-align: left;
          border-radius: 8px;
          font-weight: 500;
          font-size: var(--font-size-large);
          color: var(--dark-1);
        }
      }
    }
  }
}
</style>
